// Angular
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

// Configuracion
import { url_servicio } from '../../config/config';

// Modelos
import { AcuerdoServicio } from '../../models/models.index';

// rxjs
import { map, catchError } from 'rxjs/operators';
import { throwError as observableThrowError, Observable } from 'rxjs';

// Servicios
import { UsuarioService } from '../seguridad/usuario.service';
import { KendoService } from '../utilidades/kendo/kendo.service';
import { MensajesService } from '../utilidades/mensajes.service';

// Servicio Abstracto
import { AbtractCrudBasicoService } from '../utilidades/abstract-crud-basico.service';
@Injectable({
  providedIn: 'root'
})
export class AcuerdoServicioSlaService extends AbtractCrudBasicoService {
  /**
   * Contructor del Servicio
   * @constructor
   * @param {HttpClient} http Servicio http de angular.
   * @param {UsuarioService} _usuarioService Servicio de usuario.
   * @param {KendoService} _kendoService Servicio kendo.
   * @param {MensajesService} _mensajeService Servicio para el manejo de mensajes.
   */
  constructor(
    public http: HttpClient,
    public _usuarioService: UsuarioService,
    public _kendoService: KendoService,
    public _mensajeService: MensajesService
  ) {
    // http, _usuarioService, _kendoService, _mensajeService, 'pathName', 'tableName'
    super(http, _usuarioService, _kendoService, _mensajeService, 'acuerdoservicio', 'acuerdo_servicio');
  }

  /**
   * Función para generar reporte mantenimiento previsto ATYDE
   * @param {String} idAcuerdo
   * @param {String} idEmpresaContratente
   */
  generarReporteMantenimiento(idAcuerdo: String, idEmpresaContratente: String) {
    // Headers
    const headers = new HttpHeaders({
      token_autorizado: this._usuarioService.token_autorizado,
      'Content-Type': 'application/json',
    });
    // URL
    const url = `${url_servicio}/${this.pathName}/generarReporteMantenimiento/${idAcuerdo}/${idEmpresaContratente}`;
    return this.http.get(url, { headers }).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((err) => {
        this._mensajeService.errorCritico();
        return observableThrowError(err);
      })
    );
  }
   
}
