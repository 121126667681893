// Angular
import { Router } from '@angular/router';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
  HttpErrorResponse
} from '@angular/common/http';
import { Injectable } from '@angular/core';

// RXJS
import { Observable, BehaviorSubject, throwError } from 'rxjs';
import { catchError, filter, take, switchMap, map } from 'rxjs/operators';

// Servicio

// BryJS
import * as BryJS from 'crypto-js';

// MomentJS
import * as _moment from 'moment';
const moment = (_moment as any).default ? (_moment as any).default : _moment;

// Constantes
import {
  idServicio,
  idServicioV,
  longitud
} from '../../config/config';
import { MensajesService, UsuarioService } from '../service.index';

@Injectable()
export class HttpPetitionInterceptor implements HttpInterceptor {
  // Variables para el proceso
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );
  isRefreshingToken = false;
  isUnauthorized = false;

  constructor(
    private usuarioService: UsuarioService,
    private mensajeService: MensajesService,
    private router: Router
  ) { }

  /**
   * METODO INTERCEPTO HTTP PARA ENCRIPTAR Y DESENCRIPTAR
   * LA INFORMACIÓN DEL DCGS
   *
   * req
   * next
   * @returns intercept
   */
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // Encripta la información del body antes de enviar la petición
    if (req.body) {
      if (req.url.includes('dcGS')) {
        req = req.clone({
          body: this.ef(req.body),
        });
      }
    }

    // Verificación del TOKEN de usuario
    return this.validandoToken(req, next).pipe(
      catchError((err: HttpErrorResponse) => {
        if (err.status === 401 && err.url.includes('dcGS') && !err.url.includes('rest/autenticar')) {
          if (!this.isUnauthorized) {

            this.isUnauthorized = true;
            this.mensajeService.tokenCaducado().then(() => {
              this.usuarioService.borrarLocalStorage();
              this.isUnauthorized = false;
              window.location.reload();
            });
          }
        }

        if (err.status === 500 && err.url.includes('dcGS')) {
          this.mensajeService.errorCritico();
        }

        return throwError(err);
      }),
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          // Si el servidor devuelve en el body la variable DCG desencripta la información
          // caso contrario devuelve la respuesta original del servidor,
          if (event.body && event.body.dcgs) {
            const body = event.body.dcgs.split(longitud);
            return event.clone({
              headers: req.headers,
              body: this.fm(body[0], body[1]),
            });
          } else {
            return event;
          }
        }
      })
    );
  }

  private validandoToken(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // Obtiene el payload JWT
    if (
      this.usuarioService.isLogged() &&
      request.url.includes('dcGS') &&
      !request.url.includes('rest/autenticar')
    ) {
      const payload = JSON.parse(
        atob(this.usuarioService.token_autorizado.split('.')[1])
      );

      // Si el token ya fue expirado se envia al usuario a login
      // para que obtenga nuevamente las credenciales
      if (this.expirado(payload.exp)) {
        this.mensajeService.tokenCaducado().then(() => {          
          this.usuarioService.borrarLocalStorage();
          this.isUnauthorized = false;
          window.location.reload();
        });
      }

      // Si está dentro de la última hora se renueva el token automáticamente    }
      if (this.proximoCaducarse(payload.exp)) {
        if (!this.isRefreshingToken) {
          this.isRefreshingToken = true;
          this.refreshTokenSubject.next(null);

          return this.usuarioService.renuevaToken().pipe(
            switchMap((resp: any) => {
              this.isRefreshingToken = false;
              this.refreshTokenSubject.next(resp.token_autorizado);
              return next.handle(this.addAuthenticationToken(request));
            })
          );
        } else {
          return this.refreshTokenSubject.pipe(
            filter(token => token != null),
            take(1),
            switchMap(jwt => {
              return next.handle(this.addAuthenticationToken(request));
            })
          );
        }
      }
      return next.handle(this.addAuthenticationToken(request));
    }
    return next.handle(request);
  }

  addAuthenticationToken(request: HttpRequest<any>) {
    // We clone the request, because the original request is immutable
    return request.clone({
      setHeaders: {
        token_autorizado: this.usuarioService.token_autorizado,
        'Content-Type': 'application/json',
        empresa_contratante: this.usuarioService.idEmpresaContratanteGlobal
      }
    });
  }

  // Función que valida si el token está expirado
  expirado(fechaExp: number): boolean {
    const dateExpiration: _moment.Moment = new moment.unix(fechaExp);
    const nowDate: _moment.Moment = new moment();
    return dateExpiration.isBefore(nowDate);
  }

  // Función que valida si el token está dentro de los ultimos 20 minutos
  proximoCaducarse(fechaExp: number): boolean {
    const dateExpiration: _moment.Moment = new moment.unix(fechaExp);
    dateExpiration.add(-60, 'minutes');

    const startDate: _moment.Moment = new moment();
    startDate.add(-60, 'minutes');
    const endDate: _moment.Moment = new moment();

    return (
      dateExpiration.isAfter(startDate) && dateExpiration.isBefore(endDate)
    );
  }

  private fm(texto: string, idProspectov: string) {
    try {
      const fm = BryJS.AES.decrypt(texto, BryJS.enc.Base64.parse(idServicio), {
        mode: BryJS.mode.CBC,
        iv: BryJS.enc.Base64.parse(idProspectov),
        padding: BryJS.pad.Pkcs7
      });
      return JSON.parse(fm.toString(BryJS.enc.Utf8));
    } catch (error) {
      this.usuarioService.borrarLocalStorage();
      this.router.navigate(['/login']);
    }
  }

  private ef(texto) {
    try {
      const data = JSON.stringify(texto);
      const ef = BryJS.AES.encrypt(data, BryJS.enc.Base64.parse(idServicio), {
        mode: BryJS.mode.CBC,
        iv: BryJS.enc.Base64.parse(idServicioV),
        padding: BryJS.pad.Pkcs7
      });
      if (texto !== Object(texto)) {
        return texto;
      } else {
        return JSON.parse('{"dcgs":"' + ef.toString() + '"}');
      }
    } catch (error) {
      this.usuarioService.borrarLocalStorage();
      this.router.navigate(['/login']);
    }
  }
}
