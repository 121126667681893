// Globales
export { LoadingService } from './loading/loading.service';
export { EmpresaContratanteService } from './empresa-contratante.service';

// Guards
export { VerificaTokenGuard } from './guards/verifica-token.guard';

// #region Shared
export { NavbarService } from './shared/navbar.service';
// #endregion

// #region Seguridad
export { AccesoService } from './seguridad/acceso.service';
export { RolService } from './seguridad/rol.service';
export { AccionService } from './seguridad/accion.service';
export { UsuarioService } from './seguridad/usuario.service';
export { PerfilService } from './seguridad/perfil.service';
export { DiaHoraAccesoService } from './seguridad/dia-hora-acceso.service';

// #endregion

// #region Utilidades
export { CrudBasicoService } from './utilidades/crud-basico.service';
export { ValidarFormularioService } from './utilidades/validar-formulario.service';
export { MensajesService } from './utilidades/mensajes.service';
export { KendoUtilsService } from './utilidades/kendo/kendo-utils.service';
export { KendoService } from './utilidades/kendo/kendo.service';
export { StatePersistingKendoService } from './utilidades/kendo/state-persisting-kendo.service'
export { GDriveService } from './utilidades/google/gdrive.service';
// #endregion

// #region Ubicación
export { PaisService } from './ubicacion/pais.service';
export { ProvinciaService } from './ubicacion/provincia.service';
export { CiudadService } from './ubicacion/ciudad.service';
// #endregion

// #region Empresas
export { GrupoEmpresarialService } from './empresas/grupo-empresarial.service';
export { EmpresaService } from './empresas/empresa.service';
export { PuntoServicioService } from './empresas/punto-servicio.service';
export { ZonaService } from './empresas/zona.service';
// #endregion

// #region Personas
export { TituloService } from './personas/titulo.service';
export { CargoService } from './personas/cargo.service';
export { PersonaService } from './personas/persona.service';
export { TipoRecargoService } from './personas/tipo-recargo.service';
export { HorarioTrabajoEmpleadoService } from './personas/horario-trabajo-empleado.service';
export { DetalleHorarioTrabajoEmpleadoService } from './personas/detalle-horario-trabajo-empleado.service';

// #endregion

// #region Inventario
export { IdentificacionTipoProductoService } from './inventario/identificacion-tipo-producto.service';
export { TipoProductoService } from './inventario/tipo-producto.service';
export { UnidadMedidaService } from './inventario/unidad-medida.service';
export { FactorCalculoService } from './inventario/factor-calculo.service';
export { PorcentajeCalculoService } from './inventario/porcentaje-calculo.service';
export { MarcaService } from './inventario/marca.service';
export { ModeloService } from './inventario/modelo.service';
export { CapacidadService } from './inventario/capacidad.service';
export { UbicacionService } from './inventario/ubicacion.service';
export { ElementoService } from './inventario/elemento.service';
export { TipoMovimientoService } from './inventario/movimiento/tipo-movimiento.service';
export { MovimientoService } from './inventario/movimiento/movimiento.service';
export { DetalleMovimientoService } from './inventario/movimiento/detalle-movimiento.service';
// #endregion

// #region Servicio
export { UnidadNegocioService } from './servicio/unidad-negocio.service';
export { SubunidadNegocioService } from './servicio/subunidad-negocio.service';
export { ProyectoService } from './servicio/proyecto.service';
export { TipoMarcaVerificacionService } from './servicio/tipo-marca-verificacion.service';
export { MarcaVerificacionService } from './servicio/marca-verificacion.service';
export { CondicionCierreService } from './servicio/condicion-cierre.service';
export { ComentarioService } from './servicio/comentario.service';
export { GrupoCampoServicioService } from './servicio/grupo-campo-servicio.service';
export { CampoServicioService } from './servicio/campo-servicio.service';
export { TipoServicioService } from './servicio/tipo-servicio.service';
export { TipoBonificacionService } from './servicio/tipo-bonificacion.service';
export { FaseTrabajoService } from './servicio/fase-trabajo.service';
export { PlantillaServicioService } from './servicio/plantilla-servicio.service';
export { TipoEstadoServicioService } from './servicio/tipo-estado-servicio.service';
export { ServicioService } from './servicio/servicio.service';
export { FechaRequerimientoService } from './servicio/fecha-requerimiento.service';
export { EquipoSistemaRequeridoService } from './servicio/equipo-sistema-requerido.service';
export { ArchivoAdjuntoServicioService } from './servicio/archivo-adjunto-servicio.service';
export { ArchivoAdjuntoServicioMarcaVerificacionService } from './servicio/archivo-adjunto-servicio-marca-verificacion.service';
export { EquipoSistemaAtendidoService } from './servicio/equipo-sistema-atendido.service';
export { RepuestoUtilizadoService } from './servicio/repuesto-utilizado.service';
export { EpRespuestoUtilizadoService } from './servicio/ep-respuesto-utilizado.service';
export { MaterialUtilizadoService } from './servicio/material-utilizado.service';
export { HoraServicioTrabajoService } from './servicio/hora-servicio-trabajo.service';
export { HoraServicioTransporteService } from './servicio/hora-servicio-transporte.service';
export { ServicioMarcaVerificacionService } from './servicio/servicio-marca-verificacion.service';
export { EstadoServicioService } from './servicio/estado-servicio.service';
export { DetalleServicioMarcaVerificacionService } from './servicio/detalle-servicio-marca-verificacion-service';
export { InvolucradoService } from './servicio/involucrado.service';
export { TipoEtiquetaServicioService } from './servicio/tipo-etiqueta-servicio.service';
export { EtiquetaServicioService } from './servicio/etiqueta-servicio.service';
export { CalculoHorarioTrabajoServicioService } from './servicio/calculo-horario-trabajo-servicio.service';
export { CalculoHorarioTransporteServicioService } from './servicio/calculo-horario-transporte-servicio.service';
export { ArchivoAdjuntoEquipoSistemaAtendidoService } from './servicio/archivo-adjunto-equipo-sistema-atendido.service';
export { ArchivoAdjuntoGenericoService } from './archivo-adjunto-genrico/archivo-adjunto-generico.service';
// #endregion

// #region Acuerdos de Servicio
export { TipoCoberturaService } from './acuerdo-servicio/tipo-cobertura.service';
export { CoberturaService } from './acuerdo-servicio/cobertura.service';
export { TipoContratoService } from './acuerdo-servicio/tipo-contrato.service';
export { AcuerdoServicioService } from './acuerdo-servicio/acuerdo-servicio.service';
export { EmpresasConveniosService } from './acuerdo-servicio/empresas-convenios.service';
export { ValorHoraTecnicoService } from './acuerdo-servicio/valor-hora-tecnico.service';
export { MantenimientoPrevistoContratoService } from './acuerdo-servicio/mantenimiento-previsto-contrato.service';
export { PeriodoService } from './acuerdo-servicio/periodo.service';
export { DiaCoberturaContratoService } from './acuerdo-servicio/dia-cobertura-contrato.service';

//New
export { AcuerdoServicioSlaService } from './acuerdo-servicio/acuerdo-servicio-sla.service';
export { ServicioPrevistoService } from './acuerdo-servicio/servicio-previsto.service';
export { PlanificadorServicioService } from './acuerdo-servicio/planificador-servicio.service';
export { DashboardAcuerdoServicioService } from './acuerdo-servicio/dashboard-acuerdo-servicio.service';
// #endregion

// #region Ofertas
export { EstadoCotizacionService } from './oferta/estado-cotizacion.service';
export { CotizacionService } from './oferta/cotizacion.service';
export { DetalleCotizacionService } from './oferta/detalle-cotizacion.service';
export { ArchivoAdjuntoCotizacionService } from './oferta/archivo-adjunto-cotizacion.service';
// #endregion

// #region Consultas
export { DashboardService } from './consultas/dashboard.service';
export { AtydeDashboardService } from './consultas/atyde-dashboard.service';
export { ExportExcelCabeceraService } from './utilidades/export-excel-cabecera.service';
export { ReporteHorasTrabajadasService } from './consultas/reporte-horas-trabajadas.service';
export { ReporteHorasExtrasService } from './consultas/reporte-horas-extras.service';
export { ReporteDetalleHoraTecnicoService } from './consultas/reporte-detalle-hora-tecnico.service';
export { ReporteServiciosPendientesService } from './consultas/reporte-servicios-pendientes.service';
export { ReporteServiciosEjecutadosService } from './consultas/reporte-servicios-ejecutados.service';
export { ReporteServiciosEjecutadosEquipoService } from './consultas/reporte-servicios-ejecutados-equipo.service';
export { ReporteServiciosEjecutadosRepuestoService } from './consultas/reporte-servicios-ejecutados-repuesto.service';
export { ReporteServiciosEjecutadosNovedadService } from './consultas/reporte-servicios-ejecutados-novedad.service';
export { ReporteServiciosEjecutadosMaterialService } from './consultas/reporte-servicios-ejecutados-material.service';
export { ReporteReposicionService } from './consultas/reporte-reposicion.service';
export { ReporteEquiposDecisionService } from './consultas/reporte-equipos-decision.service';
export { FacturacionRepuestosService } from './consultas/facturacion-repuestos.service';
export { ReporteTiempoGestionServicioService } from './consultas/reporte-tiempo-gestion-servicio.service';
export { FacturacionServicioService } from './consultas/facturacion-servicio.service';
export { InventarioKardexService } from './consultas/inventario-kardex.service';
export { InventarioCustodiaClienteService } from './consultas/inventario-custodia-cliente.service';
export { InventarioReposicionService } from './consultas/inventario-reposicion.service';
export { InventarioComponentesService } from './consultas/inventario-componentes.service'
export { ReporteChecklistService } from './consultas/reporte-checklist.service';
export { ReporteEquipoSinMantenimientoService } from './consultas/reporte-equipo-sin-mantenimiento.service';
export { ReporteServiciosFaseTrabajoService } from './consultas/reporte-servicios-fase-trabajo.service';
export { ReporteOfertaService } from './consultas/reporte-oferta.service';
export { CalculoHorasTrabajoEmpleadoService } from './consultas/calculo-horas-trabajo-empleado.service';
// #endregion

// #region Graficos
export { GraficosHoraServicioTop10ClienteService } from './consultas/graficos-hora-servicio-top-10-cliente.service';
export { GraficosHoraServicioClienteService } from './consultas/graficos-hora-servicio-cliente.service';
export { GraficosHoraServicioTop10TecnicoService } from './consultas/graficos-hora-servicio-top-10-tecnico.service';
export { GraficosHoraServicioTecnicoService } from './consultas/graficos-hora-servicio-tecnico.service';
// #endregion

// #region Checklist
export { TipoCategoriaChecklistService } from './checklist/tipo-categoria-checklist.service';
export { ChecklistService } from './checklist/checklist.service';
export { CategoriaChecklistService } from './checklist/categoria-checklist.service';
export { TipoRespuestaService } from './checklist/tipo-respuesta.service';
export { PreguntaChecklistService } from './checklist/pregunta-checklist.service';
export { RespuestaChecklistService } from './checklist/respuesta-checklist.service';
export { EtiquetaMatrizService } from './checklist/etiqueta-matriz.service';
export { ChecklistDataService } from './checklist/checklist-data.service';
export { ChecklistDetalleService } from './checklist/checklist-detalle.service';
export { ChecklistDetalleObservacionService } from './checklist/checklist-detalle-observacion.service';
// #endregion

// #region Notficacion
export { NoticiaService } from './notificacion/noticia.service';
export { ContenidoNoticiaService } from './notificacion/contenido-noticia.service';
export { NotificacionEmailService } from './notificacion/notificacion-email.service';
// #endregion

// #region Empresa Suscriptora
export { EmpresaSuscriptoraService } from './empresa-suscriptoras/empresa-suscriptora.service';
export { ArchivoAdjuntoEmpresaContratanteService } from './empresa-suscriptoras/archivo-adjunto-empresa-contratante.service';
export { PoliticasSeguridadService } from './empresa-suscriptoras/politicas-seguridad.service';
export { ConsentimientoService } from './empresa-suscriptoras/consentimiento.service';
export { TerminosCondicionesService } from './empresa-suscriptoras/terminos-condiciones.service';

// #endregion

// #region VIEW
export { ConsultaEpRepuestosUtilizadosService } from './view/consulta-ep-repuestos-utilizados.service';
export { ViewReportesServiciosEjecutadosService } from './view/view-reportes-servicios-ejecutados.service';
export { ConsultaReporteHorasTrabajasSinTransporteService } from './view/consulta-reporte-horas-trabajas-sin-transporte.service';
// #endregion

// Facturacion
export { DocumentoService } from './facturacion/documento.service';
export { TipoDocumentoService } from './facturacion/tipo-documento.service';

// Evaluación de Servicios
export { FormularioEvaluacionServicioService } from './servicio/formulario-evaluacion-servicio.service';
export { PreguntaEvaluacionService } from './servicio/pregunta-evaluacion.service';
export { CondicionEvaluacionService } from './servicio/condicion-evaluacion.service';
export { EvaluacionServicioService } from './servicio/evaluacion-servicio.service';
export { ConsultaEvaluacionServiciosService } from './consultas/consulta-evaluacion-servicios.service';



//Refactorizacion

//#region Codigo Reutilizable
export { DocumentsMethodsService } from './utilidades/code-generico/documents-methods.service';
export { GenericMethodsService } from './utilidades/code-generico/generic-methods.service';
export { StatesMethodsService } from './utilidades/code-generico/states-methods.service';

//#endregion
