// Angular
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';

// RXJS
import { throwError as observableThrowError, Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

// Archivo de configuración
import { url_servicio } from '../../config/config';

// Servicio
import { MensajesService } from '../utilidades/mensajes.service';
import { KendoUtilsService } from '../utilidades/kendo/kendo-utils.service';

// SweetAlert
import Swal from 'sweetalert2'
import { State } from '@progress/kendo-data-query';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { Usuario } from '../../models/seguridad/usuario.model';

// JQuery
declare const $: any;

@Injectable({
  providedIn: 'root'
})
export class UsuarioService {
  // Inicialización de variables
  idEmpresaContratanteGlobal;
  tableName = 'usuario';

  // Variables
  token_autorizado = '';
  usuario: Usuario;
  superAdministrador: boolean;
  administrador: boolean;
  supervisorServicio: boolean;
  tecnico: boolean;
  recursosHumanos: boolean;
  empresa_contratante: string;
  empresa_contratante_default: string;
  paisesCobertura: string;

  /**
   * Contructor del Servicio
   * @constructor
   * @param {HttpClient} http Servicio http de angular.
   * @param {Router} router Servicio de router.
   */
  constructor(
    public http: HttpClient,
    public router: Router,
    private _kendoUtilsService: KendoUtilsService,
    private mensajesService: MensajesService
  ) {
  }

  /**
   * Obtiene el registro por ID de la persona
   * @param {number} idPersona - ID del registro
   */
  getByIDPersona(idPersona: string) {
    // Headers
    const headers = new HttpHeaders({
      token_autorizado: this.token_autorizado,
      'Content-Type': 'application/json'
    });
    // URL
    const url = `${url_servicio}/${this.tableName.toLowerCase()}/idpersona/${idPersona}`;
    return this.http.get(url, { headers }).pipe(
      map((response: any) => {
        return response;
      }),
      catchError(err => {
        Swal.fire(
          'Error Crítico',
          'Por favor comunicate con el proveedor del servicio',
          'error'
        );
        return observableThrowError(err);
      })
    );
  }

  /**
   * Obtiene las tipos de elemento que se muentra en Kendo GRID Modal
   */
  getModal(state?: State): any {
    // Nueva instancia de la variable state
    const stateGrid: State = JSON.parse(JSON.stringify(state));
    this._kendoUtilsService.customizeStateGrid(this.tableName, stateGrid);

    // Headers
    const headers = new HttpHeaders({
      token_autorizado: this.token_autorizado,
      'Content-Type': 'application/json',
      empresa_contratante: this.idEmpresaContratanteGlobal
    });

    // URL
    let url = `${url_servicio}/${this.tableName.toLowerCase()}/modal/`;
    url += `${this.serialize({ data: JSON.stringify(stateGrid) })}`;

    return this.http.get(url, { headers }).pipe(
      map((response: any) => {
        return <GridDataResult>{
          data: response.data,
          total: parseInt(response.total, 10)
        };
      })
    );
  }

  /**
   * Obtiene las tipos de elemento que se muentra en Kendo GRID Modal
   */
  generarNuevoToken(): any {
    // Headers
    const headers = new HttpHeaders({
      token_autorizado: this.token_autorizado,
      'Content-Type': 'application/json',
      empresa_contratante: this.idEmpresaContratanteGlobal
    });

    // URL
    const url = `${url_servicio}/${this.tableName.toLowerCase()}/generarnuevotoken`;

    return this.http.post(url, null, { headers }).pipe(
      map((response: any) => {
        this.guardarLocalStorage(response);
        return response;
      })
    );
  }

  /**
   * Obtiene los contactos que se muentran en el combobox
   */
  getUsuariosSolicitantes(idPuntoServicio: string, state?: State): any {
    // Nueva instancia de la variable state
    const stateGrid: State = JSON.parse(JSON.stringify(state));
    this._kendoUtilsService.customizeStateGrid(this.tableName, stateGrid);

    // Headers
    const headers = new HttpHeaders({
      token_autorizado: this.token_autorizado,
      'Content-Type': 'application/json',
      empresa_contratante: this.idEmpresaContratanteGlobal
    });

    // URL
    let url = `${url_servicio}/${this.tableName.toLowerCase()}/contacto/${idPuntoServicio}/`;
    url += `${this.serialize({ data: JSON.stringify(stateGrid) })}`;

    return this.http.get(url, { headers }).pipe(
      map((response: any) => {
        return <GridDataResult>{
          data: response.data,
          total: parseInt(response.total, 10)
        };
      })
    );
  }

  /**
   * Obtiene los contactos que se muentran en el combobox
   */
  getOnlyUsuariosSolicitantes(idPuntoServicio: string, state?: State): any {
    // Nueva instancia de la variable state
    const stateGrid: State = JSON.parse(JSON.stringify(state));
    this._kendoUtilsService.customizeStateGrid(this.tableName, stateGrid);

    // Headers
    const headers = new HttpHeaders({
      token_autorizado: this.token_autorizado,
      'Content-Type': 'application/json',
      empresa_contratante: this.idEmpresaContratanteGlobal
    });

    // URL
    let url = `${url_servicio}/${this.tableName.toLowerCase()}/contactoonly/${idPuntoServicio}/`;
    url += `${this.serialize({ data: JSON.stringify(stateGrid) })}`;

    return this.http.get(url, { headers }).pipe(
      map((response: any) => {
        return <GridDataResult>{
          data: response.data,
          total: parseInt(response.total, 10)
        };
      })
    );
  }

  /**
   * Obtiene los tecnicos que se muentran en el combobox
   */
  getUsuariosResponsables(state?: State): any {
    // Nueva instancia de la variable state
    const stateGrid: State = JSON.parse(JSON.stringify(state));
    this._kendoUtilsService.customizeStateGrid(this.tableName, stateGrid);

    // Headers
    const headers = new HttpHeaders({
      token_autorizado: this.token_autorizado,
      'Content-Type': 'application/json',
      empresa_contratante: this.idEmpresaContratanteGlobal
    });

    // URL
    let url = `${url_servicio}/${this.tableName.toLowerCase()}/tecnico/`;
    url += `${this.serialize({ data: JSON.stringify(stateGrid) })}`;

    return this.http.get(url, { headers }).pipe(
      map((response: any) => {
        return <GridDataResult>{
          data: response.data,
          total: parseInt(response.total, 10)
        };
      })
    );
  }

  /**
   * Comprueba si la contraseña del usuario que firma es la correcta
   */
  comprobarContrasena(idUsuario: string, contrasena: string): any {
    // Headers
    const headers = new HttpHeaders({
      token_autorizado: this.token_autorizado,
      'Content-Type': 'application/json',
      empresa_contratante: this.idEmpresaContratanteGlobal
    });

    // URL
    const url = `${url_servicio}/${this.tableName.toLowerCase()}/comprobar`;

    const params = { id: idUsuario, contrasena: contrasena };

    return this.http.post(url, params, { headers }).pipe(
      map((response: any) => {
        return response;
      })
    );
  }



  /**
   * Función para loguearse
   *
   * @param {string} usuario
   * @param {string} contrasena
   */
  login(usuario: string, contrasena: string) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    const url = url_servicio + '/autenticar/login';
    const params = { correoElectronico: usuario, contrasena: contrasena };
    return this.http.post(url, params, { headers }).pipe(
      map((response: any) => {
        if (
          response.usuario.persona.fotografia ===
          'recursos/iconos/user_default.png'
        ) {
          response.usuario.persona.fotografia = 'assets/img/user_default.png';
        }
        this.guardarLocalStorage(response);
        this.router.navigate(['/dashboard']);
        return true;
      }),
      catchError(err => {
        Swal.fire(
          'Error al iniciar sesión',
          'Usuario o contraseña incorrecta',
          'error'
        );
        return observableThrowError(err);
      })
    );
  }

  /**
   * Función para loguearse
   *
   * @param {string} usuario
   * @param {string} contrasena
   */
  recuperarContrasena(usuario: string) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    const url = url_servicio + '/autenticar/recuperarcontrasena';
    const params = { correoElectronico: usuario };
    return this.http.put(url, params, { headers }).pipe(
      map((response: any) => {
        if (response.success) {
          Swal.fire(
            'Recuperación de contraseña',
            response.mensaje,
            'success'
          );
        }
        this.usuario = null;
        this.token_autorizado = '';

        localStorage.clear();

        this.router.navigate(['/login']);
      }),
      catchError(err => {
        Swal.fire(
          'Error al iniciar sesión',
          'Usuario o contraseña incorrecta',
          'error'
        );
        return observableThrowError(err);
      })
    );
  }

  /**
   * Función para loguearse
   *
   * @param {string} usuario
   * @param {string} contrasena
   */
  loginGoogle(tokenGoogle: string) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    const url = url_servicio + '/autenticar/google';
    const params = { token: tokenGoogle };
    return this.http.post(url, params, { headers }).pipe(
      map((response: any) => {
        if (
          response.usuario.persona.fotografia ===
          'recursos/iconos/user_default.png'
        ) {
          response.usuario.persona.fotografia = 'assets/img/user_default.png';
        }
        this.guardarLocalStorage(response);
        this.router.navigate(['/configuracion']);
        return true;
      }),
      catchError(err => {
        Swal.fire(
          'Error al iniciar sesión',
          'Usuario o contraseña incorrecta',
          'error'
        );
        return observableThrowError(err);
      })
    );
  }

  /**
   * Función para cerrar sesión
   *
   * @param {string} usuario
   * @param {string} contrasena
   */
  logout() {
    this.router.navigate(['/login']).then(() => {
      this.borrarLocalStorage();
    });
  }

  /**
   * Función para renovar el token de usuario
   *
   * @param {string} usuario
   * @param {string} contrasena
   */
  renuevaToken() {
    // Headers
    const headers = new HttpHeaders({
      token_autorizado: this.token_autorizado
    });
    // URL
    const url = `${url_servicio}/autenticar/refrescartoken`;

    return this.http.get(url, { headers }).pipe(
      map((resp: any) => {
        this.token_autorizado = resp.token_autorizado;
        localStorage.setItem('token_autorizado', this.token_autorizado);
        return true;
      }),
      catchError(err => {
        this.logout();
        return observableThrowError(err);
      })
    );
  }

  saveUserConfig(userConfig: string) {
    // Headers
    const headers = new HttpHeaders({
      token_autorizado: this.token_autorizado
    });
    // URL
    const url = `${url_servicio}/usuario/userconfig`;

    return this.http.post(url, userConfig, { headers }).pipe(
      map((resp: any) => {
        return resp;
      }),
      catchError(err => {
        return observableThrowError(err);
      })
    );
  }

  /**
   * Función para saber si el usuario está logueado
   */
  isLogged(): boolean {
    return (
      this.token_autorizado &&
      this.token_autorizado !== 'undefined' &&
      this.token_autorizado.length > 5
    );
  }


  getEmpresaContratanteDefault() {
    return this.empresa_contratante_default;
  }

  /**
   * Función para saber si el usuario es superadministrador
   */
  isSuperadministrador(): boolean {
    return this.superAdministrador;
  }

  /**
   * Función para saber si el usuario es administrador
   */
  isAdministrador(): boolean {
    return this.administrador;
  }

  /**
   * Función para saber si el usuario es supervisor
   */
  isSupervisorServicio(): boolean {
    return this.supervisorServicio;
  }

  /**
   * Función para saber si es técnico
   */
  isTecnico(): boolean {
    return this.tecnico;
  }

  /**
   * Función para saber si el usuario es rrhh
   */
  isRecursosHumanos(): boolean {
    return this.recursosHumanos;
  }

  /**
   * Función para saber si el usuario es nuevo
   */
  isNuevo(): boolean {
    return this.usuario.nuevo;
  }

  /**
   * Función para saber si el usuario es nuevo
   */
  isMatrizEmpresaContratante(): boolean {
    return this.usuario.persona.empresa.matrizEmpresaContratante;
  }

  /**
   * Función para saber si debe aceptar terminos y condiciones
   */
  requiereAceptarTerminosCondiciones(): boolean {
    return !this.usuario.empresaContratante.aceptacionTerminos;
  }
  /**
   * Seteo la variable aceptacionTerminos
   */
  setEmpresaContratanteAceptacionTerminos() {
    this.usuario = JSON.parse(localStorage.getItem('usuario'));
    this.usuario.empresaContratante.aceptacionTerminos = true;
    localStorage.setItem('usuario', JSON.stringify(this.usuario));
  }

  // Serializa el state del GRID
  serialize(obj: any): string {
    const str = [];
    for (const p in obj) {
      if (obj.hasOwnProperty(p)) {
        str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]));
      }
    }
    return str.join('&');
  }
  /**
   * verifica que el token este activo y si no esta lo refresca.
   */
  verificarJWTAlive() {
    const payload = JSON.parse(atob(this.token_autorizado.split('.')[1]));
    const tokenExp = new Date(payload.exp * 1000);
    const ahora = new Date();
    // rango de una hora para que se valide el token o se renueve 3600000
    const rangoInicial = new Date(payload.exp * 1000 - 3600000);
    if (tokenExp.getTime() > ahora.getTime() && rangoInicial.getTime() < ahora.getTime()) {
      this.renuevaToken().subscribe(() => { }, () => {
        this.router.navigate(['/login']);
      });
    }
  }

  /**
   * Generar Token autorizado para que la persona que adquirio el software firme los terminos y condiciones
   * @param {number} idEmpresaContratante - ID del registro
   */
  getTokenAutorizadoRegistro(idEmpresaContratante: string) {
    // Headers
    const headers = new HttpHeaders({
      token_autorizado: this.token_autorizado,
      'Content-Type': 'application/json'
    });
    // URL
    const url = `${url_servicio}/autenticar/generatokenautorizadoregistro/${idEmpresaContratante}`;
    return this.http.get(url, { headers }).pipe(
      map((response: any) => {
        return response;
      }),
      catchError(err => {
        Swal.fire(
          'Error Crítico',
          'Por favor comunicate con el proveedor del servicio',
          'error'
        );
        return observableThrowError(err);
      })
    );
  }
  /**
   * Función para loguearse
   *
   * @param {string} usuario
   * @param {string} contrasena
   */
  loginPorToken(token: string) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    const url = url_servicio + '/autenticar/logintoken';
    const params = { token: token };
    return this.http.post(url, params, { headers }).pipe(
      map((response: any) => {
        if (
          response.usuario.persona.fotografia ===
          'recursos/iconos/user_default.png'
        ) {
          response.usuario.persona.fotografia = 'assets/img/user_default.png';
        }
        this.guardarLocalStorage(response);
        this.router.navigate(['/dashboard']);
        return true;
      }),
      catchError(err => {
        Swal.fire(
          'Error al iniciar sesión',
          'Usuario o contraseña incorrecta',
          'error'
        );
        return observableThrowError(err);
      })
    );
  }


  // VALIDACIÓN DE TOKEN
  async validaToken(returnUrl): Promise<boolean> {
    await this.cargarToken();

    // Si el token no existe
    if (!this.token_autorizado) {
      localStorage.clear();
      this.router.navigate(['/login'], { queryParams: { returnUrl } });
      return Promise.resolve(false);
    }

    const url = url_servicio + '/usuario/validarjwt';

    // Headers
    const headers = new HttpHeaders({
      token_autorizado: this.token_autorizado,
      'Content-Type': 'application/json',
      empresa_contratante: this.idEmpresaContratanteGlobal
    });

    return new Promise<boolean>(resolve => {
      this.http.get(url, { headers }).subscribe((response: any) => {

        this.usuario = response.usuario;

        // this.tokendcGServicios = resp.usuario.empresaSuscriptora.tokendcGServicios;
        // this.headerOferta = resp.usuario.empresaSuscriptora.headerOferta;
        // this.footerOferta = resp.usuario.empresaSuscriptora.footerOferta;
        // this.superadministrador = resp.superadministrador;
        // this.administrador = resp.administrador;
        // this.gerente = resp.gerente;
        // this.informacionExternos = resp.informacionExternos;
        // this.idEmpresaSuscriptora = resp.usuario.empresaSuscriptora.id;


        this.token_autorizado = response.token_autorizado;
        this.usuario = response.usuario;
        this.superAdministrador = response.superAdministrador;
        this.administrador = response.administrador;
        this.supervisorServicio = response.supervisorServicio;
        this.tecnico = response.tecnico;
        this.recursosHumanos = response.recursosHumanos;
        this.paisesCobertura = response.usuario.empresaContratante.paisesCobertura;
        this.empresa_contratante = response.usuario.empresaContratante.id;
        this.empresa_contratante_default = response.usuario.empresaContratanteDefault.id;
        this.idEmpresaContratanteGlobal = response.usuario.empresaContratante.id;
        resolve(true);
      },
        (error) => {
          this.mensajesService.tokenCaducado();
          this.usuario = null;
          this.token_autorizado = '';
          localStorage.clear();
          this.router.navigate(['/login'], { queryParams: { returnUrl } });

          return Promise.resolve(false);

        });





    });
  }

  /**
   * Función que carga el token del usuario
   */
  async cargarToken() {
    this.token_autorizado = localStorage.getItem('token_autorizado');
    this.idEmpresaContratanteGlobal = localStorage.getItem('idEmpresaContratanteGlobal');
  }

  /**
 * Función para guardar los datos al local storage
 */
  guardarLocalStorage(response) {
    localStorage.setItem('token_autorizado', response.token_autorizado);
    localStorage.setItem('idEmpresaContratanteGlobal', response.usuario.empresaContratante.id);
  }

  /**
   * Función para borrar el sessionStorage del usuario
   */
  borrarLocalStorage() {
    this.usuario = null;
    this.token_autorizado = '';
    localStorage.clear();
  }

  /**
   * Función para saber si debe aceptar politas de seguridad
   */
  politicasSeguridadAceptada(): boolean {
    return this.usuario.politicaAceptada;
  }

}
