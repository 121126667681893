<div class="row">
    <div class="col-md-12 animated fadeIn fast" *ngIf="placeholderLoading">
        <app-placeholder-loading-grid>
        </app-placeholder-loading-grid>
    </div>
    <div class="col-md-12 animated fadeIn fast " *ngIf="!placeholderLoading">
        <kendo-grid #grid [loading]="gridService.loadingGrid" [data]="dataGrid | async"
                    [pageSize]="state.take"
                    [skip]="state.skip"
                    [sort]="state.sort"
                    [filter]="state.filter"
                    [sortable]="true"
                    [pageable]="true"
                    filterable='menu'
                    [resizable]="true"
                    [reorderable]="true"
                    [columnMenu]="true"
                    (dataStateChange)="dataStateChange($event)">

            <!-- =============== -->
            <!-- TEMPLATE BUSCAR -->
            <!-- =============== -->
            <ng-template kendoGridToolbarTemplate position="top">
                <app-template-buscar-grid [accion]="accion"
                                          (cargarCrear)="cargarRegistroEvent('new')"
                                          [grid]="grid" [gridSettings]="gridSettings"
                                          (changeEstado)="filtrarEstado($event)" [estado]="estado"
                                          (buscarInfo)="searchKendo($event)"
                                          (changeSaveVista)="saveGridSettings(grid, gridSettings)"
                                          (changeRestaurarVista)="restoreGridSettings()"
                                          [menuConfiguracion]="menuConfiguracion">
                </app-template-buscar-grid>
            </ng-template>
            <!-- ===================== -->
            <!-- FIN - TEMPLATE BUSCAR -->
            <!-- ===================== -->


            <kendo-grid-column
                               *ngFor="let col of gridSettings.columnsConfig"
                               [field]="col.field"
                               [title]="col.title"
                               [width]="col._width"
                               [filter]="col.filter"
                               [filterable]="col.filterable"
                               [sortable]="col.sortable === false ? false : true"
                               [hidden]="col.hidden"
                               [exportable]="false"
                               [sticky]="col.sticky">


                <!-- TEMPLATE ETIQUETA -->
                <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex"
                             *ngIf="col.filter === 'etiquetas'">


                    <ng-container *ngIf="dataItem[col.field]">
                        <kendo-chip *ngFor="let etiqueta of (dataItem[col.field].length > 75 ?  (dataItem[col.field] | slice: 0:75).split(';') : dataItem[col.field].split(';')); let last = last"
                                    style="font-size: 10px !important;"
                                    [size]="'small'"
                                    [rounded]="'large'"
                                    [fillMode]="'outline'"
                                    [themeColor]="'success'">
                            {{etiqueta}}
                            <span style="align-items: baseline !important;"
                                  *ngIf="dataItem[col.field] && dataItem[col.field].length > 75 && last">
                                ...
                            </span>
                        </kendo-chip>
                    </ng-container>
                </ng-template>

                <!-- FILTRO BOOLEAN -->
                <ng-template kendoGridCellTemplate let-dataItem let-index="i"
                             style="width: 100% !important; text-align: center;"
                             let-rowIndex="rowIndex" *ngIf="col.filter === 'map'">
                    <a class='cursor help'
                       href="https://www.google.com/maps/search/?api=1&query={{dataItem.latitud || '-1.9191583'}},{{dataItem.longitud || '-79.1236012'}}"
                       target='_blank'>
                        <i class='material-icons'>place</i>
                    </a>
                    {{dataItem[col.field]}}
                </ng-template>
                <!-- FIN - FILTRO BOOLEAN -->

                <!-- FILTRO BOOLEAN -->
                <ng-template kendoGridCellTemplate let-dataItem let-index="i"
                             style="width: 100% !important; text-align: center;"
                             let-rowIndex="rowIndex" *ngIf="col.filter === 'boolean'">
                    <input type="checkbox" kendoCheckBox
                           [readonly]="true"
                           id="ver{{i}}" name="ver{{i}}" [(ngModel)]="dataItem[col.field]" />
                </ng-template>
                <!-- FIN - FILTRO BOOLEAN -->



                <a class='cursor help' [href]='urlGoogle'
                   target='_blank'>
                    <i class='material-icons'>arrow_right</i> <strong>Visualizar en
                        Google Maps</strong>
                </a>`
                <!-- FILTRO PICTURE -->
                <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex"
                             *ngIf="col.filter === 'picture'">
                    <div class="thumbnail img-circle">
                        <img style="max-height: 50px" [src]="dataItem.fotografia" onError="this.src='../../../assets/img/defaultUser.png'">
                    </div>
                </ng-template>
                <!-- FIN - FILTRO PICTURE -->


                <!-- INICIO COLUMNA MODULO DOCUMENTOS (Ticket de Servicio)-->
                <!-- FIN - FILTRO ARRAY -->
                <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex"
                             *ngIf="col.filter === 'array'">
                    <span *ngFor="let detalleFactura of dataItem.detallesDocumento, let i=index">
                        <span *ngIf="i<5">
                            {{detalleFactura.servicio.numeroServicio}}
                        </span>
                        <span *ngIf="i===5">
                            <br>
                            ...
                        </span>
                    </span>
                </ng-template>
                <!-- FIN - FILTRO ARRAY -->
                <!-- FIN COLUMNA MODULO DOCUMENTOS (Ticket de Servicio)-->

                <!-- FILTRO DATE -->
                <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex"
                             *ngIf="col.filter === 'date'">
                    <span>{{ dataItem[col.field] | dateTimeFormat: col.format }}</span>
                </ng-template>
                <!-- FIN - FILTRO DATE -->

                <!-- FIN - FILTRO NUMERIC -->
                <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex"
                             *ngIf="col.filter === 'numeric'">
                    <span>{{ dataItem[col.field] | numberFormat: col.format }}</span>
                </ng-template>
                <!-- FIN - FILTRO NUMERIC -->

                <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex"
                             *ngIf="col.cutText">
                    <span matSuffix [matTooltip]="dataItem[col.field].length > 200
                              ? (dataItem[col.field] | slice: 0:200) + '...'
                              : dataItem[col.field]"
                          matTooltipPosition="above"
                          matTooltipClass="tooltip-modal"
                          *ngIf="dataItem[col.field]">
                        {{
                        dataItem[col.field].length > 100
                        ? (dataItem[col.field] | slice: 0:100)
                        + "..."
                        : dataItem[col.field]
                        }}
                    </span>
                </ng-template>

                <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex"
                             *ngIf="col.field === 'estado'">
                    <span *ngIf="dataItem.estado === estadoRegistro.Activo"
                          class="badge badge-success">ACTIVO</span>
                    <span *ngIf="dataItem.estado === estadoRegistro.Inactivo"
                          class="badge badge-default">INACTIVO</span>
                </ng-template>

                <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex"
                             *ngIf="col.field === 'herramienta' && urlView">
                    <div class="text-center"
                         *ngIf="dataItem.estado === estadoRegistro.Activo && !dataItem.finalizado">

                        <!-- ADMINISTRADOR DE CONTRASENA -->
                        <a (click)="cargarModalEvent(dataItem)"
                           *ngIf="accion.editar && dataItem.accesoDcgs && (_usuarioService.isSuperadministrador() || _usuarioService.isAdministrador() || _usuarioService.isSupervisorServicio())">
                            <i matTooltip="Restaurar contraseña" matTooltipPosition="above"
                               class="material-icons boton-editar">lock</i>
                        </a>
                        <!-- FIN - ADMINISTRADOR DE CONTRASENA -->

                        <!-- ACCIÓN EDITAR / VISUALIZAR -->
                        <a [routerLink]=" [urlView, dataItem.id]">
                            <i *ngIf="accion.editar" matTooltip="Editar" matTooltipPosition="above"
                               class="material-icons boton-editar">mode_edit</i>
                            <i *ngIf="!accion.editar" matTooltip="Visualizar"
                               matTooltipPosition="above"
                               class="material-icons boton-editar">remove_red_eye</i>
                        </a>
                        <!-- FIN ACCIÓN EDITAR / VISUALIZAR -->

                        <!-- ACCIÓN INACTIVAR -->
                        <a matTooltip="{{ accion.eliminar ? 'Inactivar' : 'No tiene permiso para eliminar' }}"
                           matTooltipPosition="above" (click)="cargarEliminarEvent(dataItem)">
                            <i class="material-icons boton-eliminar"
                               [ngClass]="{ disabled: !accion.eliminar }">delete</i>
                        </a>
                        <!-- ACCIÓN INACTIVAR -->
                    </div>
                    <!-- FIN COLUMNA MODULO DOCUMENTOS (FINALIZADO)-->
                    <div class="text-center" *ngIf="dataItem.estado === estadoRegistro.Inactivo">
                        <a matTooltip="{{ accion.eliminar ? 'Activar' : 'No tiene permiso para restaurar' }}"
                           matTooltipPosition="above" (click)="cargarEliminarEvent(dataItem)">
                            <i class="material-icons boton-eliminar"
                               [ngClass]="{ disabled: !accion.eliminar }">undo</i>
                        </a>
                    </div>
                </ng-template>

                <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex"
                             *ngIf="col.field === 'herramienta' && !urlView">
                    <div class="text-center"
                         *ngIf="dataItem.estado === estadoRegistro.Activo && !dataItem.finalizado">

                        <a (click)="cargarModalEvent(dataItem)"
                           *ngIf="accion.editar && dataItem.accesoDcgs && (_usuarioService.isSuperadministrador() || _usuarioService.isAdministrador() || _usuarioService.isSupervisorServicio())">
                            <i matTooltip="Restaurar contraseña" matTooltipPosition="above"
                               class="material-icons boton-editar">lock</i>
                        </a>
                        <a (click)="cargarRegistroEvent(dataItem.id)">
                            <i *ngIf="accion.editar && !terminoPolitica" matTooltip="Editar" matTooltipPosition="above"
                               class="material-icons boton-editar">mode_edit</i>
                            <i *ngIf="!accion.editar || terminoPolitica" matTooltip="Visualizar"
                               matTooltipPosition="above"
                               class="material-icons boton-editar">remove_red_eye</i>
                        </a>
                        <a matTooltip="{{ accion.eliminar ? 'Inactivar' : 'No tiene permiso para eliminar' }}"
                           matTooltipPosition="above" (click)="cargarEliminarEvent(dataItem)">
                            <i class="material-icons boton-eliminar"
                               [ngClass]="{ disabled: !accion.eliminar }">delete</i>
                        </a>
                    </div>
                    <!-- INICIO COLUMNA MODULO DOCUMENTOS (FINALIZADO)-->
                    <div class="text-center"
                         *ngIf="dataItem.estado === estadoRegistro.Activo && dataItem.finalizado">
                        <a (click)="cargarRegistroEvent(dataItem.id)">
                            <i class="material-icons help">done</i>
                        </a>
                    </div>
                    <!-- FIN COLUMNA MODULO DOCUMENTOS (FINALIZADO)-->
                    <div class="text-center" *ngIf="dataItem.estado === estadoRegistro.Inactivo">
                        <a matTooltip="{{ accion.eliminar ? 'Activar' : 'No tiene permiso para restaurar' }}"
                           matTooltipPosition="above" (click)="cargarEliminarEvent(dataItem)">
                            <i class="material-icons boton-eliminar"
                               [ngClass]="{ disabled: !accion.eliminar }">undo</i>
                        </a>
                        <!-- INICIO COLUMNA MODULO DOCUMENTOS (ANULADO)-->
                        <a matTooltip="Anulado" *ngIf="dataItem.finalizado"
                           matTooltipPosition="above">
                            <i class="material-icons boton-eliminar">do_disturb</i>
                        </a>
                        <!-- FIN COLUMNA MODULO DOCUMENTOS (ANULADO)-->
                    </div>
                </ng-template>
            </kendo-grid-column>
            <!-- ============================================== -->

            <!-- ================ -->
            <!-- FOOTER KENDOGRID -->
            <!-- ================ -->
            <ng-template kendoPagerTemplate let-totalPages="totalPages"
                         let-currentPage="currentPage">
                <kendo-pager-prev-buttons>
                </kendo-pager-prev-buttons>
                <kendo-pager-info></kendo-pager-info>
                <kendo-pager-next-buttons>
                </kendo-pager-next-buttons>
                <kendo-pager-page-sizes [pageSizes]="[10, 25, 50]">
                </kendo-pager-page-sizes>
            </ng-template>
            <!-- ====================== -->
            <!-- FIN - FOOTER KENDOGRID -->
            <!-- ====================== -->
            <kendo-grid-excel [fileName]="obtenerNombreArchivo('xlsx')" [fetchData]="allData">
            </kendo-grid-excel>

            <kendo-grid-pdf [repeatHeaders]="false" [allPages]="true" [scale]="0.9"
                            [landscape]="true" paperSize="A3" margin="1cm" author="Decisión C.A."
                            creator="dcGS" [fileName]="obtenerNombreArchivo() + '.pdf'">
                <kendo-grid-pdf-margin left="3mm" top="3mm" right="3mm"
                                       bottom="20mm"></kendo-grid-pdf-margin>
            </kendo-grid-pdf>
        </kendo-grid>
    </div>
</div>