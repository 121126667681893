import { Empresa } from '../empresas/empresa.model';
import { EmpresaContratante } from '../empresa-contratante.model';
import { TipoServicio } from './tipo-servicio.model';
import { Proyecto } from './proyecto.model';
import { PuntoServicio } from '../empresas/punto-servicio.model';
import { UnidadNegocio } from './unidad-negocio.model';
import { SubunidadNegocio } from './subunidad-negocio.model';
import { Usuario } from '../seguridad/usuario.model';
import { TipoBonificacion } from './tipo-bonificacion.model';
import { ServicioPrevisto } from '../acuerdo-servicio/servicio-previsto.model';
import { AcuerdoServicioSla } from '../models.index';

export class Servicio {
  id?: string;
  numeroServicio?: string;
  requerimiento?: string;
  indicacionTecnica?: string;
  inspeccion?: boolean;
  garantia?: boolean;
  descripcionTrabajo?: string;
  numeroFactura?: string;
  numeroFacturaRepuestos?: string;
  permiteAceptacionCliente?: boolean;
  firmadoCliente?: boolean;
  firmadoSupervisorServicio?: boolean;
  bonificacion?: boolean;
  valorBonificacion?: number;
  horasNotificacionAcceso?: number;
  fechaAceptacionAutomatica?: Date;
  fechaNotificacionAcceso?: Date;
  accesoNotificado?: boolean;
  evaluado?: Boolean;
  ccEmailSolicitud?: string;
  ccEmailFirmadoCliente?: string;
  interno?: boolean;
  estado?: string;
  version?: number;
  empresaContratante?: EmpresaContratante;
  tipoServicio?: TipoServicio;
  subtipoServicio?: TipoServicio;
  proyecto?: Proyecto;
  acuerdoServicio?: AcuerdoServicioSla;
  puntoServicio?: PuntoServicio;
  proveedor?: Empresa;
  unidadNegocio?: UnidadNegocio;
  subunidadNegocio?: SubunidadNegocio;
  servicioGarantia?: Servicio;
  usuarioSolicitante?: Usuario;
  usuarioFirma?: Usuario;
  usuarioResponsable?: Usuario;
  tipoBonificacion?: TipoBonificacion;
  offline?: boolean;
  usuarioFirmaGrafico?: string;
  // DECISIONCLOUD GESTIÓN DE VENTAS
  idOportunidad?: string;
  numeroOportunidad?: string;
  vendedorOportunidad?: string;
  correoOportunidad?: string;

  //Transient
  servicioPrevisto?: ServicioPrevisto;
  //Campos agregados
  zonaHoraria?: string;

  constructor() {
    this.id = null;
    this.numeroServicio = null;
    this.requerimiento = null;
    this.indicacionTecnica = null;
    this.inspeccion = null;
    this.garantia = null;
    this.numeroFactura = null;
    this.numeroFacturaRepuestos = null;
    this.permiteAceptacionCliente = null;
    this.firmadoCliente = null;
    this.firmadoSupervisorServicio = null;
    this.bonificacion = null;
    this.valorBonificacion = null;
    this.horasNotificacionAcceso = null;
    this.fechaAceptacionAutomatica = null;
    this.fechaNotificacionAcceso = null;
    this.accesoNotificado = null;
    this.ccEmailSolicitud = null;
    this.ccEmailFirmadoCliente = null;
    this.zonaHoraria = null;
    this.estado = null;
    this.version = null;
    this.empresaContratante = null;
    this.tipoServicio = null;
    this.subtipoServicio = null;
    this.proyecto = null;
    this.acuerdoServicio = null;
    this.puntoServicio = null;
    this.proveedor = null;
    this.unidadNegocio = null;
    this.subunidadNegocio = null;
    this.servicioGarantia = null;
    this.usuarioSolicitante = null;
    this.usuarioFirma = null;
    this.usuarioResponsable = null;
    this.tipoBonificacion = null;
    this.servicioPrevisto = null;
  }
}
