import { EmpresaContratante } from "../empresa-contratante.model";

export class Acceso {
    id: string;
    orden?: number;
    nombre?: string;
    ruta?: string;
    icono?: string;
    tipo?: string;
    descripcion?: string;
    crear?: boolean;
    editar?: boolean;
    ver?: boolean;
    eliminar?: boolean;
    exportar?: boolean;
    administrable?: boolean;
    estado?: string;
    observacion?: string;
    fechaCreacion?: Date;
    version?: number;
    empresaContratante?: EmpresaContratante;
    raiz?: Acceso;

    constructor() {
        this.id = null;
        this.orden = null;
        this.nombre = null;
        this.ruta = null;
        this.icono = null;
        this.tipo = null;
        this.descripcion = null;
        this.crear = true;
        this.editar = true;
        this.ver = true;
        this.eliminar = true;
        this.exportar = true;
        this.administrable = true;
        this.estado = null;
        this.observacion = null;
        this.fechaCreacion = null;
        this.version = null;
        this.empresaContratante = null;
        this.raiz = null;
    }
}
