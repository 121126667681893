import { EmpresaContratante } from '../empresa-contratante.model';
import { Persona } from '../personas/persona.model';

export class Usuario {
  id?: string;
  contrasena?: string;
  accesoDcgs?: boolean;
  enviarCorreoCreaServicio?: boolean;
  enviarCorreoAsignaServicio?: boolean;
  enviarCorreoDisponibleAceptacion?: boolean;
  enviarCorreoAceptaServicio?: boolean;
  enviarCorreoReaperturaServicio?: boolean;
  enviarCorreoEliminaServicio?: boolean;
  enviarCorreoApruebaServicio?: boolean;
  enviarCorreoEtSemanal?: boolean;
  enviarCorreoEtMensual?: boolean;
  enviarCorreoEtTrimestral?: boolean;
  enviarCorreoEtAmbienteLaboral?: boolean;
  notificacionNoticia?: boolean;
  informacionPDF?: string;
  restriccionHorario?: boolean;
  nuevo?: boolean;
  tokenAsana?: string;
  userConfig?: string;
  observacion?: string;
  fechaCreacion?: Date;
  estado?: string;
  version?: number;
  empresaContratante?: EmpresaContratante;
  empresaContratanteDefault?: EmpresaContratante;
  persona?: Persona;
  politicaAceptada?: boolean;

  constructor() {
    this.id = null;
    this.contrasena = null;
    this.accesoDcgs = null;
    this.enviarCorreoCreaServicio = null;
    this.enviarCorreoAsignaServicio = null;
    this.enviarCorreoDisponibleAceptacion = null;
    this.enviarCorreoAceptaServicio = null;
    this.enviarCorreoReaperturaServicio = null;
    this.enviarCorreoEliminaServicio = null;
    this.enviarCorreoApruebaServicio = null;
    this.enviarCorreoEtSemanal = null;
    this.enviarCorreoEtMensual = null;
    this.enviarCorreoEtTrimestral = null;
    this.enviarCorreoEtAmbienteLaboral = null;
    this.notificacionNoticia = null;
    this.informacionPDF = null;
    this.restriccionHorario = null;
    this.nuevo = null;
    this.tokenAsana = null;
    this.userConfig= null;
    this.observacion = null;
    this.fechaCreacion = null;
    this.estado = null;
    this.version = null;
    this.empresaContratante = null;
    this.persona = null;
    this.politicaAceptada = null;
  }
}
