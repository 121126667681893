// LOCALHOST
// export const url_servicio = 'http://localhost:8080/dcGS_v4_Prueba/rest';
// export const url_venta = 'http://localhost:4201';


// PRUEBAS
// export const url_servicio = 'https://mydecisioncloud.com/dcGS_v4_Prueba/rest';
// export const url_venta = 'https://pruebasventas.mydecisioncloud.com/';

// PRODUCCION
export const url_servicio = 'https://mydecisioncloud.com/dcGS_v4/rest';
export const url_venta = 'https://ventas.mydecisioncloud.com';

// DEMO
// export const url_servicio = 'https://mydecisioncloud.com/dcGS_v4_Demo/rest';
// export const url_venta = 'https://demoventas.mydecisioncloud.com';

export const isAmbienteDemo = false; // Solo TRUE cuando se actualice el Demo 

// Cambiar al nuevo ID
export const idEmpresaContratanteDecision = '5cf589e75e76ef29eadf0d0162';
export const idEmpresaContratanteDecisionCloud = '5cf589e75e76ef29eadf0d0172';
export const idEmpresaContratanteEnergyPlam = '5d8245ef9e53d706671adaaf74';
export const idDecisionCA = '5b0c603809784a675c96e2d087';

// Versión Aplicacion
export const version = 'v5.4.1';

//Key google maps
export const keyGoogleMaps = 'AIzaSyBKixaV25SlN7giG__FNsTg-3GF_yTybAM';

export const idServicio = 'FYZgVWMEMTr9Ask3zl6ybomeqXN/nXpq2OHiI+A4TLs=';
export const idServicioV = 'Y3JlZlJscWVQUmFzNURBdA==';
export const longitud = 'QTTH1haG2G7wu8udxGpYr7WgyOHCtiPjViWFaklUKkw';

