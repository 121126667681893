// Angular
import { Component } from '@angular/core';

// Dialogo Angular/Material
import { MatDialog } from '@angular/material/dialog';

// PerfectScrollbar
import PerfectScrollbar from 'perfect-scrollbar';

// Modelos
import { EmpresaContratante, Menu } from '../../models/models.index';

// Servicios
import {
  AccesoService,
  UsuarioService,
  NavbarService,
  EmpresaContratanteService
} from '../../services/service.index';

// Componentes
import { PerfilPersonaComponent } from '../perfil-persona/perfil-persona.component';
import { MaterialApoyoComponent } from '../../pages/soporte/material-apoyo/material-apoyo.component';

// Enums
import { SizeModal } from '../../config/size-modal.enum';
import { ConfiguracionEmpresaSuscriptoraComponent } from '../configuracion-empresa-suscriptora/configuracion-empresa-suscriptora.component';
import { Router } from '@angular/router';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { BehaviorSubject } from 'rxjs';
import { State } from '@progress/kendo-data-query';

// Jquery
declare const $: any;

@Component({
  selector: 'app-sidebar',
  templateUrl: 'sidebar.component.html'
})
export class SidebarComponent {
  // Inicialización de variables para el proceso
  public menuItems: Menu[] = [];
  placeholderLoading = false;

  filtroEmpresaContratante = '';
  empresaContratanteTmp: EmpresaContratante = new EmpresaContratante();
  empresasContratantes: GridDataResult = { data: [], total: 0 };
  stateSubjectEmpresaContratante = new BehaviorSubject<string>(null);
  empresaContratanteActual = '';

  public stateEmpresaContratante: State = {
    skip: 0,
    take: 25,
    sort: [
      // Orden por defecto
      { dir: 'asc', field: 'nombreCorto' },
    ],
    // Initial filter descriptor
    filter: {
      logic: 'and',
      filters: [],
    },
  };


  /**
   * Contructor del componente
   * @constructor
   * @param {AccesoService} _accesoService Servicio de acceso.
   * @param {UsuarioService} _usuarioService Servicio de usuario.
   * @param {NavbarService} _navbarService Servicio de navbar para el cambio de titulo e icono
   * @param {MatDialog} dialog Diálogo Material.
   */
  constructor(
    private _accesoService: AccesoService,
    public _usuarioService: UsuarioService,
    public _navbarService: NavbarService,
    private dialog: MatDialog,
    private _empresaContratanteService: EmpresaContratanteService,    
    private router: Router
  ) {
    this.placeholderLoading = true;
    // Carga los accesos del sistema
    this._accesoService.obtenerMenuPrincipal().subscribe(menuItems => {
      this.menuItems = menuItems;
      this.placeholderLoading = false;
      this.updatePS();
    });

    this.cargarEmpresaContratante().then(() => {
      for (const obj of this.empresasContratantes.data) {
        if (obj.id === this._usuarioService.idEmpresaContratanteGlobal) {
          this.empresaContratanteTmp = obj;
        }
      }
    });
  }

  isMobileMenu() {
    if ($(window).width() > 991) {
      return false;
    }
    return true;
  }

  updatePS(): void {
    if (window.matchMedia(`(min-width: 960px)`).matches && !this.isMac()) {
      const elemSidebar = <HTMLElement>document.querySelector(
        '.sidebar .sidebar-wrapper'
      );
      const ps = new PerfectScrollbar(elemSidebar, {
        wheelSpeed: 2,
        suppressScrollX: true
      });
    }
  }

  isMac(): boolean {
    let bool = false;
    if (
      navigator.platform.toUpperCase().indexOf('MAC') >= 0 ||
      navigator.platform.toUpperCase().indexOf('IPAD') >= 0
    ) {
      bool = true;
    }
    return bool;
  }

  /**
   * Función que permite cargar el modal de perfil de usuario
   */
  cargarPerfil(): void {
    const dialogRef = this.dialog.open(PerfilPersonaComponent, {
      disableClose: true,
      hasBackdrop: true,
      width: SizeModal.large,
      data: {
        usuario: this._usuarioService.usuario
      }
    });
  }
    /**
   * Función que permite cargar el modal de configuracion empresa suscriptora
   */
  cargarConfiguracionEmpresaSuscriptora(): void {
    const dialogRef = this.dialog.open(ConfiguracionEmpresaSuscriptoraComponent, {
      disableClose: true,
      hasBackdrop: true,
      width: SizeModal.large,
      data: {
        usuario: this._usuarioService.usuario,
        idEmpresaContratante: this._usuarioService.usuario.empresaContratante.id
      }
    });
  }

  cargarModalSoporte(){
   const dialogRef = this.dialog.open(MaterialApoyoComponent , {
    disableClose: false,
    hasBackdrop: true,
    width: SizeModal.large,
    data: {
      url: 'https://view.genial.ly/6495ba0b01436d001806eb88'
    }
   })
  }

  ///////////////////////////////
  ////  Empresas Contrantes ////
  /////////////////////////////

  /**
   * Función para cargar las Empresas Contrantes
   */
  cargarEmpresaContratante(): Promise<string> {
    return new Promise((resolve, reject) => {
      this._empresaContratanteService
        .getModal(this.stateEmpresaContratante)
        .subscribe((empresasContratantes) => {         
          this.empresasContratantes = empresasContratantes;
          this.cargarEmpresaActual();
          resolve('');
        });
    });
  }

  cargarEmpresaActual(){
    let empresaContratanteTmp: any = this.empresasContratantes.data.filter(empresaContratante => empresaContratante.id == this._usuarioService.idEmpresaContratanteGlobal);
    this.empresaContratanteActual = empresaContratanteTmp[0].nombreCorto;          
  }
  /**
   * (PRUEBA) Cambio de empresa contratante solo para el super administrador
   */
  cambiarEmpresaContrante(empresaContratante) {
    this._usuarioService.idEmpresaContratanteGlobal = empresaContratante.id;
    this._usuarioService
    .generarNuevoToken()
    .subscribe((resp) => {
      if(resp){
        this.router.navigate(['/dashboard']).then(()=>{
          window.location.reload();
        });
      }
    });
  }
  /**
   * Función para cargar el filtro de Empresas Contratantes
   */
  filterChangeEmpresaContratante(e) {
    this.filtroEmpresaContratante = e.toUpperCase();
    this.stateSubjectEmpresaContratante.next(e);
  }
}
