//Angular
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

//Servicios
import { MensajesService } from '../mensajes.service';
import Swal from 'sweetalert2'
import { UntypedFormGroup } from '@angular/forms';
import { IdentificacionTipoProductoEnum } from 'src/app/config/identificacion-tipo-producto.enum';
import { EvaluacionServicioService } from '../../servicio/evaluacion-servicio.service';

// Moment
import * as _moment from 'moment';
const moment = (_moment as any).default ? (_moment as any).default : _moment;
import * as momentTz from 'moment-timezone';

@Injectable({
  providedIn: 'root'
})
export class GenericMethodsService {

  identificacionTipoProductoEnum = IdentificacionTipoProductoEnum;

  constructor(
    private _router: Router,
    private _mensajesService: MensajesService,
    private _evaluacionServicioService: EvaluacionServicioService,
  ) { }

  //Cerrar Pagina
  closePage(formulario: UntypedFormGroup, ruta: string) {
    if (formulario.dirty) {
      Swal
        .fire({
          text: "Los cambios efectuados en el formulario no se guardarán. ¿Está seguro de que desea continuar?",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#ffa726',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Si',
          cancelButtonText: 'Cancelar'
        })
        .then((result) => {
          if (result.value) {
            document.body.style.overflow = "";
            this._router.navigate([`${ruta}`]);
          }
        });
    } else {
      this._router.navigate([`${ruta}`]);
    }
  }

  //Normailiza el termino a buscar
  safelyParseJSON(jsonString) {
    let parsed;
    try {
      parsed = JSON.parse(jsonString);
    } catch (e) {
      // Oh well, but whatever...
    }
    return parsed; // Could be undefined!
  }


  //Area de correo
  /*Validadcion de email/correo correcto*/
  validateEmail(email) {
    const re = /^(([^<>()\[\]\\.,;:\s@']+(\.[^<>()\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  /*Agrega uno o varios correo/s*/
  addCorreoCopia(input, value, correosCopia): void {
    // Add our fruit
    if ((value || '').trim() && this.validateEmail(value)) {
      correosCopia.push(value.trim());
    } else {
      if (value.length > 0) {
        this._mensajesService.errorPersonalizada(
          'El correo ingresado no tiene un formato válido',
          ''
        );
      }
    }
    // Reset the input value
    if (input) {
      input.value = '';
    }
  }

  removeCorreoCopia(correosCopia: any, correo: string): void {
    const index = correosCopia.indexOf(correo);
    if (index >= 0) {
      correosCopia.splice(index, 1);
    }
  }

  //Cambiar de Estado
  cambiarEstado(accion: any, entidad: any, servicio: any, state: any, kendoView = false): Promise<string> {
    return new Promise((resolve: any) => {
      // Asigna el state por defecto en el caso de que no tenga asignado uno
      if (accion.eliminar) {
        this._mensajesService
          .confirmacionCambiarEstado(entidad)
          .then((confirmacion: boolean) => {
            if (confirmacion) {
              servicio
                .cambiarEstado(entidad)
                .subscribe(() => {
                  if (kendoView) {
                    resolve(servicio.getKendoView(state));
                  } else {
                    resolve(servicio.getKendo(state));
                  }
                });
            }
          });
      }
    });
  }

  popcenter({ url, w, h }) {
    // Fixes dual-screen position                             Most browsers      Firefox
    const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screenX;
    const dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screenY;

    const width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width;
    const height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height;

    const systemZoom = width / window.screen.availWidth;
    const left = (width - w) / 2 / systemZoom + dualScreenLeft
    const top = (height - h) / 2 / systemZoom + dualScreenTop
    const newWindow = window.open(url, '',
      `
      scrollbars=yes,
      width=${w / systemZoom}, 
      height=${h / systemZoom}, 
      top=${top}, 
      left=${left}
      `
    )

    if (window.focus) newWindow.focus();
    return newWindow;
  }

  esEquipoHerramienta(formulario: UntypedFormGroup): boolean {
    if (formulario.controls.tipoProducto && formulario.controls.tipoProducto.value) {
      const elementoComprobar = formulario.controls.tipoProducto.value;

      return elementoComprobar.identificacionTipoProducto.id === this.identificacionTipoProductoEnum.Equipo ||
        elementoComprobar.identificacionTipoProducto.id === this.identificacionTipoProductoEnum.Herramienta;
    }
    return false;
  }

  convertirFechaPorZonaHoraria(fechaServicio: string, zonaHoraria: string): Date {
    // Crea un objeto Moment con la fecha, hora y la zona horaria correspondiente
    const fechaZona = momentTz.tz(fechaServicio, 'YYYY-MM-DD HH:mm:ssZ', zonaHoraria);
    // Formatea la fecha y hora según el formato requerido
    const fechaFormateada = fechaZona.format("YYYY-MM-DD HH:mm:ss");
    // Retorna la fecha como un objeto Date
    return new Date(fechaFormateada);
  }

  // FUnción que convierte la fecha a la zona horaria correspondiente sin realizar conversión de horas
  convertirFechaAUTC(fecha, zonaHoraria) {
    // Convierte el objeto Date a un objeto Moment
    const fechaMoment = moment(fecha);
  
    // Configura la zona horaria especificada sin ajustar la hora
    fechaMoment.tz(zonaHoraria, true);
  
    // Convierte la fecha a UTC
    const fechaUTC = fechaMoment.clone().utc();
  
    // Formatea la fecha en el formato ISO 8601
    return fechaUTC.format();
  }

  //Obtener imagen de la calificacion del servicio
  obtenerCalificacion(idServicio: string): Promise<string> {
    return new Promise((resolve: any, reject) => {
      const urlBase = "../../../../assets/img/icon_evaluacion/";
      this._evaluacionServicioService.getByServicio(idServicio).subscribe((data) => {
        if (data.length > 0) {
          const evaluacion = data[0]; // Tomar el primer elemento (siempre y cuando exista)
          if (evaluacion) {
            const tipoCalificacion = evaluacion.preguntaEvaluacion.formularioEvaluacion.escalaNumerica;
            const calificacion = evaluacion.valor;

            const tipoCalificacionToURL = {
              "emoji": {
                3: "Emoji3-17112023.png",
                2: "Emoji2-17112023.png",
                1: "Emoji1-17112023.png"
              },
              "estrella": {
                1: "Estrella1-17112023.png",
                2: "Estrella2-17112023.png",
                3: "Estrella3-17112023.png",
                4: "Estrella4-17112023.png",
                5: "Estrella5-17112023.png"
              },
              "numerica": {
                1: "Numerica1-17112023.png",
                2: "Numerica2-17112023.png",
                3: "Numerica3-17112023.png",
                4: "Numerica4-17112023.png",
                5: "Numerica5-17112023.png",
                6: "Numerica6-17112023.png",
                7: "Numerica7-17112023.png",
                8: "Numerica8-17112023.png",
                9: "Numerica9-17112023.png",
                10: "Numerica10-17112023.png"
              }
            };

            const urlEvaluacion = tipoCalificacionToURL[tipoCalificacion]?.[calificacion];
            if (urlEvaluacion) {
              resolve(`${urlBase}${urlEvaluacion}`);
            } else {
              // Manejar caso no mapeado
              reject('URL no encontrada, Evaluacion omitida o no realizada');
            }
          }
        }
      });
    });
  }
}
