import { Ciudad } from './ubicacion/ciudad.model';
import { ArchivoAdjuntoEmpresaContratante } from './empresa-suscriptora/archivo-adjunto-empresa-contratante.model';
import { TerminoCondicion } from './empresa-suscriptora/termino-condicion.model';

export class EmpresaContratante {
    id: string;
    ruc?: string;
    nombreCompleto?: string;
    nombreCorto?: string;
    siglas?: string;
    telefonoFijo?: string;
    telefonoMovil?: string;
    sitioWeb?: string;
    mailContacto?: string;
    mailRecibeNotificaciones?: string;
    calle?: string;
    numero?: string;
    interseccion?: string;
    datosReferencia?: string;
    logoPrincipal?: string;
    logoSecundario?: string;
    logoCsc?: string;
    informacionPdf?: string;
    observacion?: string;
    fechaCreacion?: Date;
    estado?: string;
    version?: number;
    representanteLegalNombre?: string;
    representanteLegalMail?: string;
    contactoVentaNombre?: string;
    contactoVentaMail?: string;
    latitud?: number;
    longitud?: number;
    administradorNombre?: string;
    administradorMail?: string;
    administradorTelefono?: string;
    facturacionNombre?: string;
    facturacionMail?: string;
    facturacionTelefono?: string;
    aceptacionTerminos?: boolean;
    fechaAceptacionTerminos?: Date;
    ciudad?: Ciudad;
    archivoAdjuntoEmpresaContratante?: ArchivoAdjuntoEmpresaContratante[];
    paisesCobertura?: string;
    notificacionGerencialEmail?: string;
    headerPdf?: string;
    // @Trasient
    nombreTerminoCondicion?: string;
    fechaVigenciaTerminoCondicion?: Date;
    urlContratoTerminoCondicion?: string;
    urlOfertaTerminoCondicion?: string;

    constructor() {
        this.id = null;
        this.ruc = null;
        this.nombreCompleto = null;
        this.nombreCorto = null;
        this.siglas = null;
        this.telefonoFijo = null;
        this.telefonoMovil = null;
        this.sitioWeb = null;
        this.mailContacto = null;
        this.mailRecibeNotificaciones = null;
        this.calle = null;
        this.numero = null;
        this.interseccion = null;
        this.datosReferencia = null;
        this.logoPrincipal = null;
        this.logoSecundario = null;
        this.logoCsc = null;
        this.informacionPdf = null;
        this.observacion = null;
        this.fechaCreacion = null;
        this.estado = null;
        this.version = null;
        this.representanteLegalNombre = null;
        this.representanteLegalMail = null;
        this.contactoVentaNombre = null;
        this.contactoVentaMail = null;
        this.latitud = null;
        this.longitud = null;
        this.administradorNombre = null;
        this.administradorMail = null;
        this.administradorTelefono = null;
        this.facturacionNombre = null;
        this.facturacionMail = null;
        this.facturacionTelefono = null;
        this.aceptacionTerminos = null;
        this.fechaAceptacionTerminos = null;
        this.archivoAdjuntoEmpresaContratante = null;
        this.paisesCobertura = null;
        this.notificacionGerencialEmail = null;
        this.headerPdf = null;
        // @Trasient
        this.nombreTerminoCondicion = null;
        this.fechaVigenciaTerminoCondicion = null;
        this.urlContratoTerminoCondicion = null;
        this.urlOfertaTerminoCondicion = null;
    }
}
