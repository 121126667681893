// Angular
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

// rxjs
import { map, catchError } from 'rxjs/operators';
import { throwError as observableThrowError, Observable } from 'rxjs';

// Servicios
import { UsuarioService } from '../seguridad/usuario.service';
import { KendoService } from '../utilidades/kendo/kendo.service';
import { MensajesService } from '../utilidades/mensajes.service';

// Servicio Abstracto
import { AbtractCrudBasicoService } from '../utilidades/abstract-crud-basico.service';
import { url_servicio } from '../../config/config';

// SweetAlert
import Swal from 'sweetalert2'

@Injectable({
  providedIn: 'root',
})
export class ServicioService extends AbtractCrudBasicoService {
  /**
   * Contructor del Servicio
   * @constructor
   * @param {HttpClient} http Servicio http de angular.
   * @param {UsuarioService} _usuarioService Servicio de usuario.
   * @param {KendoService} _kendoService Servicio kendo.
   * @param {MensajesService} _mensajeService Servicio para el manejo de mensajes.
   */
  constructor(
    public http: HttpClient,
    public _usuarioService: UsuarioService,
    public _kendoService: KendoService,
    public _mensajeService: MensajesService
  ) {
    // http, _usuarioService, _kendoService, _mensajeService, 'pathName', 'tableName'
    super(http, _usuarioService, _kendoService, _mensajeService, 'servicio');
  }

  /**
   * Función para crear o editar el registro
   * @param {Persona} persona - Entidad a crear o editar
   */
  saveSolicitud(entidad: any) {
    // Headers
    const headers = new HttpHeaders({
      token_autorizado: this._usuarioService.token_autorizado,
      'Content-Type': 'application/json',
    });
    // URL
    const url = `${url_servicio}/servicio`;

    // Crear
    if (entidad.servicio.id) {
      return this.http.put(url, entidad, { headers }).pipe(
        map((response: any) => {
          if (response.success) {
            this._mensajeService.exito(response.mensaje);
          } else {
            this._mensajeService.error(response.error);
          }
          return response;
        }),
        catchError((err) => {
          this._mensajeService.errorCritico();
          return observableThrowError(err);
        })
      );
    } else {
      return this.http.post(url, entidad, { headers }).pipe(
        map((response: any) => {
          return response;
        }),
        catchError((err) => {
          this._mensajeService.errorCritico();
          return observableThrowError(err);
        })
      );
    }
  }

  /**
   * Función para volver un servicio al estado de edición
   * @param {String} idServicio - Entidad a crear o editar
   */
  volverEstadoEdicion(idServicio: String) {
    // Headers
    const headers = new HttpHeaders({
      token_autorizado: this._usuarioService.token_autorizado,
      'Content-Type': 'application/json',
    });
    // URL
    const url = `${url_servicio}/servicio/volverestadoedicion/${idServicio}`;
    return this.http.put(url, {}, { headers }).pipe(
      map((response: any) => {
        if (response.success) {
          this._mensajeService.exito(response.mensaje);
        } else {
          this._mensajeService.error(response.error);
        }
        return response;
      }),
      catchError((err) => {
        this._mensajeService.errorCritico();
        return observableThrowError(err);
      })
    );
  }

  /**
   * Función para aceptar el servicio
   * @param idServicio Identificador del servicio
   * @param idUsuarioFirma Identificador del Usuario que firma
   * @param contrasenaPDF Contraseña concatenada con el PDF en bse64 que se envía al correo
   */
  aceptarServicio(
    idServicio: string,
    idUsuarioFirma: string,
    contrasenaPDF: string,
    evaluado: Boolean
  ) {
    // Headers
    const headers = new HttpHeaders({
      token_autorizado: this._usuarioService.token_autorizado,
      'Content-Type': 'application/json',
    });
    // URL
    const url = `${url_servicio}/${this.tableName}/firmacliente/${idServicio}/${idUsuarioFirma}/${evaluado}`;
    return this.http.post(url, contrasenaPDF, { headers }).pipe(
      map((response: any) => {
        if (response.success) {
          Swal.fire('Información', response.mensaje, 'success');
        } else {
          Swal.fire(response.error.mensaje, response.error.detalle, 'error');
        }
        return response;
      }),
      catchError((err) => {
        this._mensajeService.errorCritico();
        return observableThrowError(err);
      })
    );
  }

  /**
   * Función para aprobar el servicio
   * @param idServicio Identificador del servicio
   * @param bonificacionse bonificaciones
   */
  aprobarServicio(entidad) {
    // Headers
    const headers = new HttpHeaders({
      token_autorizado: this._usuarioService.token_autorizado,
      'Content-Type': 'application/json',
    });
    // URL
    const url = `${url_servicio}/${this.tableName}/firmasupervisor`;
    return this.http.post(url, entidad, { headers }).pipe(
      map((response: any) => {
        if (response.success) {
          Swal.fire('Información', response.mensaje, 'success');
        } else {
          Swal.fire(response.error.mensaje, response.error.detalle, 'error');
        }
        return response;
      }),
      catchError((err) => {
        this._mensajeService.errorCritico();
        return observableThrowError(err);
      })
    );
  }

  /**
   * Función para reaperturar el servicio
   * @param idServicio Identificador del servicio
   * @param idUsuarioFirma Identificador del Usuario que firma
   * @param contrasenaPDF Contraseña concatenada con el PDF en bse64 que se envía al correo
   */
  repaerturarServicio(idServicio: string) {
    // Headers
    const headers = new HttpHeaders({
      token_autorizado: this._usuarioService.token_autorizado,
      'Content-Type': 'application/json',
    });
    // URL
    const url = `${url_servicio}/${this.tableName}/reaperturar/${idServicio}`;
    return this.http.post(url, {}, { headers }).pipe(
      map((response: any) => {
        if (response.success) {
          Swal.fire('Información', response.mensaje, 'success');
        } else {
          Swal.fire(response.error.mensaje, response.error.detalle, 'error');
        }
        return response;
      }),
      catchError((err) => {
        this._mensajeService.errorCritico();
        return observableThrowError(err);
      })
    );
  }

  /**
   * Función para generar el PDF del reporte de servicio ATYDE
   * @param {String} idServicio
   * @param {String} idEmpresaContratente
   */
  generarReporte(idServicio: String, idEmpresaContratente: String) {
    // Headers
    const headers = new HttpHeaders({
      token_autorizado: this._usuarioService.token_autorizado,
      'Content-Type': 'application/json',
    });
    // URL
    const url = `${url_servicio}/${this.tableName}/generarReporte/${idServicio}/${idEmpresaContratente}`;
    return this.http.get(url, { headers }).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((err) => {
        this._mensajeService.errorCritico();
        return observableThrowError(err);
      })
    );
  }

  /**
   * Función que permite previsualizar el PDF del servicio - Empresa Suscriptora
   * @param {String} idEmpresaContratente
   */
  previsualizarServicioPDF(idEmpresaContratente: string, header: string, footer: string) {
    // Headers
    const headers = new HttpHeaders({
      token_autorizado: this._usuarioService.token_autorizado,
      'Content-Type': 'application/json',
    });

    // URL
    const url = `${url_servicio}/${this.tableName}/previsualizarPDF`;

    const param = { id: idEmpresaContratente, informacionPdf: footer, headerPdf: header };

    return this.http.post(url, param, { headers }).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((err) => {
        this._mensajeService.errorCritico();
        return observableThrowError(err);
      })
    );
  }

  /**
   * Función para generar el PDF del resumen de servicio
   * @param {String} idServicio
   * @param {String} idEmpresaContratente
   */
  generarPDFResumen(idServicio: String, idEmpresaContratente: String) {
    // Headers
    const headers = new HttpHeaders({
      token_autorizado: this._usuarioService.token_autorizado,
      'Content-Type': 'application/json',
    });
    // URL
    const url = `${url_servicio}/${this.tableName}/generarPDF/${idServicio}/${idEmpresaContratente}`;
    return this.http.get(url, { headers }).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((err) => {
        this._mensajeService.errorCritico();
        return observableThrowError(err);
      })
    );
  }

  //#region OFFLINE
  /**
   * Función para cambiar el estado de visible o no visible
   * @param {String} idArchivoAdjunto
   */
  cambiarOnline(idServicio: String) {
    // Headers
    const headers = new HttpHeaders({
      token_autorizado: this._usuarioService.token_autorizado,
      'Content-Type': 'application/json',
    });
    // URL
    const url = `${url_servicio}/${this.tableName}/online/${idServicio}`;
    return this.http.put(url, {}, { headers }).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((err) => {
        this._mensajeService.errorCritico();
        return observableThrowError(err);
      })
    );
  }
  //#endregion
}
