// Módulos de angular
import { RouterModule, Routes } from '@angular/router';

// Módulos con acceso al público
import { NopagefoundComponent } from './shared/nopagefound/nopagefound.component';

// Componentes de la aplicación
import { PagesComponent } from './pages/pages.component';

// Guards
import { VerificaTokenGuard } from './services/service.index';

const routes: Routes = [
  // Rutas con acceso al público
  {
    path: 'login',
    loadChildren: () =>
      import('./public/login/login.module').then(m => m.LoginModule)
  },
  {
    path: 'recuperar-contrasena',
    loadChildren: () =>
      import('./public/recuperar-contrasena/recuperar-contrasena.module').then(
        m => m.RecuperarContrasenaModule
      )
  },
  {
    path: 'terminos-condiciones',
    loadChildren: () =>
      import('./public/terminos-condiciones/terminos-condiciones.module').then(
        m => m.TerminosCondicionesModule
      )
  },
  {
    path: 'politicas-seguridad',
    loadChildren: () =>
      import('./public/politica-aprobar/politica-aprobar.module').then(
        m => m.PoliticaAprobarModule
      )
  },
  // Rutas de la aplicación pasando por un guard de login
  {
    path: '',
    component: PagesComponent,
    canActivate: [VerificaTokenGuard],
    children: [
      // Redirección a Dashboard
      {
        path: '',
        redirectTo: '/dashboard',
        pathMatch: 'full'
      },
      {
        path: 'dashboard',
        loadChildren: () =>
          import('./pages/dashboard/dashboard.module').then(m => m.DashboardModule),

      },
      // // Acuerdos de Servicios
      // {
      //   path: 'acuerdo-servicio',
      //   loadChildren: () =>
      //     import('./pages/acuerdo-servicio/acuerdo-servicio.module').then(
      //       m => m.AcuerdoServicioModule
      //     )
      // },
      // New Acuerdos de Servicios
      {
        path: 'acuerdos-servicio',
        loadChildren: () =>
          import('./pages/refactoring/modulo-acuerdo-servicio/modulo-acuerdo-servicio.module').then(
            m => m.ModuloAcuerdoServicioModule
          )
      },
      //Planificador de servicios
      {
        path: 'planificador-servicios',
        loadChildren: () =>
          import('./pages/refactoring/modulo-acuerdo-servicio/planificador-servicios/planificador-servicios.module').then(
            m => m.PlanificadorServiciosModule
          )
      },
      // Facturación
      {
        path: 'documentos',
        loadChildren: () =>
          import('./pages/documento/documento.module').then(
            m => m.FacturacionModule
          )
      },
      // Sección Ofertas
      {
        path: 'oferta',
        loadChildren: () =>
          import('./pages/oferta/oferta.module').then(m => m.OfertaModule),

      },
      // Sección configuración
      {
        path: 'configuracion',
        loadChildren: () =>
          import('./pages/configuracion/configuracion.module').then(
            m => m.ConfiguracionModule
          )
      },
      // Sección servicio
      {
        path: 'servicio',
        loadChildren: () =>
          import('./pages/servicio/servicio/servicio-ejecucion.module').then(
            m => m.ServicioEjecucionModule
          )
      },
      // Sección inventarios - procesos
      {
        path: 'inventario',
        loadChildren: () =>
          import('./pages/inventario/movimiento/movimiento.module').then(
            m => m.MovimientoModule
          )
      },
      // Sección Consultas
      {
        path: 'consultas',
        loadChildren: () =>
          import('./pages/consultas/consultas.module').then(
            m => m.ConsultasModule
          )
      },
      // Sección Noticias DCGS
      {
        path: 'notificacion',
        loadChildren: () =>
          import('./pages/notificacion/notificacion.module').then(
            m => m.NotificacionModule
          )
      },
      {
        path: 'soporte',
        loadChildren: () =>
          import('./pages/soporte/material-apoyo/material-apoyo.module').then(
            m => m.MaterialApoyoModule
          )
      },
      // Sección Empresa Suscriptora
      // {
      //   path: 'empresa-suscriptora',
      //   loadChildren: () =>
      //     import(
      //       './pages/empresa-suscriptoras/empresa-suscriptoras.module'
      //     ).then(m => m.EmpresaSuscriptorasModule)
      // },
      // Sección Refactorizada
      {
        path: 'inventarios',
        loadChildren: () =>
          import(
            './pages/refactoring/modulo-inventario/modulo-inventario.module'
          ).then(m => m.ModuloInventario)
      },
      // Sección Empresa Refactorizada
      {
        path: 'empresas',
        loadChildren: () =>
          import(
            './pages/refactoring/modulo-empresas/modulo-empresas.module'
          ).then(m => m.ModuloEmpresasModule)
      },
      // Sección Empresa suscriptora Refactorizada
      // {
      //   path: 'empresas-suscriptora',
      //   loadChildren: () =>
      //     import(
      //       './pages/refactoring/modulo-empresa-suscriptora/modulo-empresa-suscriptora.module'
      //     ).then(m => m.ModuloEmpresaSuscriptoraModule)
      // },
      {
        path: 'configuracion',
        loadChildren: () =>
          import(
            './pages/refactoring/modulo-servicio/modulo-servicio.module'
          ).then(m => m.ModuloServicioModule)
      }
    ]
  },
  // Redirecciónamiento ruta inexistente
  { path: '**', component: NopagefoundComponent }
];

// Exportación de las rutas de la aplicación
export const appRoutes = RouterModule.forRoot(routes);
